/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AuditLogEntity {
    BAN = 'ban',
    COMMENT = 'comment',
    HOMEPAGE = 'homepage',
    BLOCKED_DOMAIN = 'blocked-domain',
    USER = 'user',
    SUBSCRIPTION = 'subscription',
}
